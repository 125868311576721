$(document).ready(function ($) {

    $(document).find('.block-theme-index').each(function (item, element) {
        initThemeBlockIndexContainer($(element));
    });
});


function initThemeBlockIndexContainer($blockIndexContainer) {
    $blockIndexContainer.on('keyup', '.js-theme-index-search', function () {
        filterIndexThemes($blockIndexContainer, $(this));
    });
}

function filterIndexThemes($blockIndexContainer, $searchInput) {
    console.log('filterThemes');

    let $themeIndexBar = $blockIndexContainer.find('.theme-index-search-bar');


    let inputVal = $searchInput.val().trim();
    if (inputVal.length < 1) {
        console.log('empty stuff');
        $blockIndexContainer.find(".theme-index-letter-section-list-item").removeClass('d-none');
    }
    console.log(inputVal);

    var filterString = inputVal.replace(/\./g, '\.');
    var rDomein = new RegExp(filterString.replace(/\*/, ".*"), "i");
    let letters = [];
    var letter = '';

    $blockIndexContainer.find(".theme-index-letter-section-list-item").each(function () {

        let $sectionListItem = $(this);
        $sectionListItem.removeClass('d-none');

        let listItemTitle = $(this).data('item-title');
        if (!listItemTitle) {
            return;
        }

        if (!rDomein.test(listItemTitle)) {
            //{# Verberg item #}
            $sectionListItem.addClass('d-none');
        } else {
            //{# Onthoud eerste letter #}
            letter = $sectionListItem.parents('.theme-index-letter-section').data('letter');
            if (letters.indexOf(letter) < 0) {
                letters.push(letter);
            }
        }

    });

    $themeIndexBar.find('.theme-index-search-bar-letter-filter').removeClass('empty-section');
    $blockIndexContainer.find(".theme-index-letter-section").each(function () {

        $(this).removeClass('empty-section');
        letter = $(this).data('letter');
        if (letters.indexOf(letter) < 0) {
            $(this).addClass('empty-section');

            $themeIndexBar.find('.theme-index-search-bar-letter-filter[data-letter="' + letter + '"]').addClass('empty-section');
        }
    });
}

