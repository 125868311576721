let $likeButton,
    $commentsContainer,
    $numComments,
    $numLikes,
    $readMoreButtonComments,
    $readMoreContainerComments;

$(document).ready(function ($) {
    $likeButton = $('.js-like-button');
    $numLikes = $('.js-num-likes');
    $commentsContainer = $('.js-comments');
    $numComments = $commentsContainer.find('li').length;
    $readMoreContainerComments = $('.js-read-more-container-comments');
    $readMoreButtonComments = $('.js-read-more-button-comments');

    $likeButton.on('click', function(e){
        const commentId = $(this).data('comment');
        addLike(commentId);
        $(this).addClass('is_clicked');
    });

    if($numComments >= 4){
        $readMoreContainerComments.addClass('show');
        $commentsContainer.addClass('limitShow');
    }

    addClickReadMore($readMoreButtonComments,$readMoreContainerComments ,$commentsContainer);
});


function addLike(commentId){
    $.ajax({
        url: linku_ajax_url,
        type: 'post',
        data: {
            action: 'add_like',
            commentId: commentId
        },
        dataType: 'json',
        success: function (response) {
            let comment = $('.comment-'+commentId);
            console.log("response ", response);
            comment.html(response.likes);
        },
        error: function (response) {
            console.log('error', response);
        }
    });
}
