let $toggleFAQ,
    $faqSearchInput,
    $searchedContainer,
    $searchValue;

$(document).ready(function ($) {
    $faqSearchInput = $('.js-faq-search');
    $searchedContainer = $('.js-searched-container');
    initFaq();
});


function getFaqs(){
    $loaders.addClass('is_loading');
    $.ajax({
        url: linku_ajax_url,
        type: 'post',
        data: {
            action: 'load_faq',
            search_value: $searchValue
        },
        dataType: 'json',
        success: function (response) {
            $toggleFAQ.unbind();
            console.log("response ", response);
            $searchedContainer.html(response.postsHtml);
            initFaq();
            $loaders.removeClass('is_loading');

        },
        error: function (response) {
            console.log('error', response);
            $loaders.removeClass('is_loading');
        }
    });
}


function initFaq(){
    // $queryString = window.location.search;

    $toggleFAQ = $('.js-toggle-faq');
    $toggleFAQ.on('click', function (e) {
        console.log("click click");
        e.stopPropagation();
        var activeClassName = 'is-open';

        if ($(this).hasClass(activeClassName)) {
            $(this).removeClass(activeClassName);
            $(this).next().slideUp();
        } else {
            $toggleFAQ.removeClass(activeClassName);
            $toggleFAQ.next().slideUp();

            $(this).addClass(activeClassName);
            $(this).next().slideDown();
        }
    });

}

function searchFaq() {
    $searchValue = $faqSearchInput.val();
    getFaqs();
}

function updateSearch(){
    if($faqSearchInput.val() == ''){
        $searchedContainer.html('');
    }else{
        $searchValue = $faqSearchInput.val();
        getFaqs();
    }
}
