let $tocChildren,
    $tocChildToggle,
    $toc,
    $tocLink,
    $tocContainerToggle,
    $tocContainer,
    $scrollContent;

$(document).ready(function ($) {
    $tocChildren    = $('.js-toc-children');
    $tocChildToggle = $('.js-toc-child-toggle');
    $toc            = $('.js-toc');
    $tocLink        = $('.js-toc-link');
    $tocContainer   = $('.js-toc-container');
    $tocContainerToggle= $('.js-toc-container-toggle');
    $scrollContent   =$(".js-scroll-content")
    initTocClick();
    initTocToggle();
    initTocScroll();

    $tocContainerToggle.click(function(){
        const activeClass = 'is-active';

        if ($(this).hasClass(activeClass)) {
            $(this).removeClass(activeClass);
            $tocContainer.slideUp();
        } else {
            $(this).addClass(activeClass);
            $tocContainer.slideDown();
        }
    })

    if($scrollContent){
        monitorActiveTocItem($scrollContent);
    }

});

function initTocClick() {
    $tocLink.click(function() {
        activateTocItem($(this));
    });
}

function activateTocItem($tocItem) {
    var activeClass = 'is-active';

    $tocLink.removeClass(activeClass);
    $tocItem.addClass(activeClass);
}

function initTocToggle() {
    $tocChildToggle.click(function() {
        var activeClass = 'is-active';

        $tocChildren.slideUp();

        if ($(this).hasClass(activeClass)) {
            $(this).removeClass(activeClass);
        } else {
            $tocChildToggle.removeClass(activeClass);
            $(this).addClass(activeClass);
            var tocIndex = $(this).data('toc-toggle');
            $('[data-toc-children="' + tocIndex + '"]').slideDown();
        }
    });
}

function initTocScroll() {
    if (!isMobile() && !isTablet()) {
        var fixedClass = 'is-fixed';

        $(window).scroll(function () {
            $toc.each(function() {
                var $currentToc = $(this);

                if ($currentToc.hasClass('is-active')) {
                    if ($(window).scrollTop() > getPosition($currentToc[0]).y) {
                        $currentToc.addClass(fixedClass)
                    } else if($currentToc.hasClass(fixedClass)) {
                        $currentToc.removeClass(fixedClass)
                    }
                }
            });
        });
    }
}

function monitorActiveTocItem(content) {
    if (!isMobile() && !isTablet()) {
        var isScrolling;

        $(window).scroll(function() {
            window.clearTimeout(isScrolling);

            isScrolling = setTimeout(function() {
                var $headings = $(content).find('h2, h3');
                var $closestHeading = findClosestHeading($headings);
                var $tocItem = $('a[href="#' + $closestHeading.attr('id') + '"]');

                activateTocItem($tocItem);
            }, 100);
        });
    }
}

function findClosestHeading($headings) {
    let closestHeading;

    $headings.each(function (index, heading) {
        if (index === 0) {
            closestHeading = heading;
        }

        var headingTopDiff = Math.abs($(window).scrollTop() - getPosition(heading).y);
        var closestHeadingTopDiff = Math.abs($(window).scrollTop() - getPosition(closestHeading).y);
        if (headingTopDiff < closestHeadingTopDiff) {
            closestHeading = heading;
        }
    });
    return $(closestHeading);
}
