let $bibliography,
    $literaturePointers,
    $numLiItems,
    $readMoreButtonBibliography,
    $readMoreContainerBibliography;


// Use the window load function for getting the correct offset for span, otherwise it's not working for some reason ¯\_(ツ)_/¯
$(window).load(function(){
    $bibliography = $('.js-bibliography');
    $literaturePointers = $('.js-bibliography-item');
    $readMoreContainerBibliography = $('.js-read-more-container-bibliography');
    $readMoreButtonBibliography = $('.js-read-more-button-bibliography');
    $numLiItems = $bibliography.find('li').length;


    $('.js-bibliography li').each(function(i) {
        $(this).attr('id', i+1);
        $(this).prepend("<span class='c-bibliography'>"+ (i+1)+"</span>");
    });

    if($numLiItems >= 7){
        $readMoreContainerBibliography.addClass('show');
        $bibliography.addClass('limitShow');
    }
    addClickReadMore($readMoreButtonBibliography, $readMoreContainerBibliography, $bibliography);


    $literaturePointers.each(function(i) {
        const item = $('#'+ $(this).data('lit-id')).html();
        const elemPos = $(this).offset();
        let wrapperClass = 'c-bibliography__shortcode-text'
        if(elemPos.left <= 130){
            wrapperClass = wrapperClass + ' setLeft'
        }
        const posRight = $( window ).width() - (elemPos.left + ($(this).width() / 2));
        if(posRight <= 130){
            wrapperClass = wrapperClass + ' setRight'

        }
        const newhtml = $(this).data('lit-id') + "<span class='"+wrapperClass+"'><span class='c-bibliography__shortcode-text-number'>"+$(this).data('lit-id')+"</span><div class='c-bibliography__triangle'></div>"+item+"</span>"
        $(this).html(newhtml);
    });
})


