// eslint-disable-next-line no-unused-vars
class Accordion {
  constructor(accordionSelector, toggleAccordionSelector, contentSelector) {
    this.accordionSelector = accordionSelector;
    this.contentSelector = contentSelector;
    this.$accordions = $(this.accordionSelector);
    this.$toggleAccordion = $(toggleAccordionSelector);
    this.ACTIVE_CLASS = 'is-open';
  }

  static toggle(event) {
    event.stopPropagation();
    const { self } = event.data;

    const $accordion = $(event.target).closest(self.accordionSelector);

    if ($accordion.hasClass(self.ACTIVE_CLASS)) {
      $accordion.removeClass(self.ACTIVE_CLASS);
      $accordion.find(self.contentSelector).stop().slideUp();
    } else {
      const $faqContent = self.$accordions.find(self.contentSelector);
      self.$accordions.removeClass(self.ACTIVE_CLASS);
      $faqContent.stop().slideUp();
      self.$toggleAccordion.attr('aria-expanded', false);
      $faqContent.attr('aria-hidden', true);

      $accordion.addClass(self.ACTIVE_CLASS);
      $accordion.find(self.contentSelector).stop().slideDown();
    }

    $(event.target).attr('aria-expanded', $accordion.hasClass(self.ACTIVE_CLASS));
    $accordion.find(self.contentSelector).attr('aria-hidden', !$accordion.hasClass(self.ACTIVE_CLASS));
  }

  initClickHandling() {
    this.$toggleAccordion.on('click', {
      self: this,
    }, Accordion.toggle);
  }
}
