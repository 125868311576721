let $searchModal,
    $searchModalShow,
    $searchModalHide,
    $searchModalSearchInput
;

$(document).ready(function ($) {
    $searchModal = $("#js-search-modal");
    $searchModalShow = $(".js-search-modal-show");
    $searchModalHide = $(".js-search-modal-hide");
    $searchModalSearchInput = $("#js-search-input");

    $searchModalShow.click(function() {
        $searchModal.addClass('c-search-modal--show');
        $searchModalSearchInput.focus();
    });
    $searchModalHide.click(function() {
        $searchModal.removeClass('c-search-modal--show');
    });

});

