function isMobile() {
    var mobileBreakpoint = 768;
    return window.matchMedia("only screen and (max-width:" + mobileBreakpoint + "px)").matches;
}

function isTablet() {
    var mobileBreakpoint = 992;
    return window.matchMedia("only screen and (max-width:" + mobileBreakpoint + "px)").matches;
}

function getPosition(element) {
    var headerHeight = 116;
    var xPosition = 0;
    var yPosition = 0;

    while(element) {
        xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition - headerHeight };
}
