let $filterItems;
let $searchInput;
let $sortInput;
let $resetFilters;
let $postsContainer;
let $filterContainer;
let $paginationContainer;
let $toggleFilters;
let $numPosts;
let $loaders;
let $pagination;
let $filterPopup;
let $toggleFilterPopup;
let $relatedPostContainer;
let $spinner;

let currentPage = 1;
let firstLoad = true;

let post_type;
let popup_status;
let sort_option;
let search_value;
let filters = [];
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

$(document).ready(($) => {
  $postsContainer = $('.js-posts-container');
  $filterContainer = $('.js-filter-container');
  $paginationContainer = $('.js-pagination-container');
  $searchInput = $('.js-filter-search');
  $sortInput = $('.js-sort');
  $numPosts = $('.js-num-posts');
  $loaders = $('.js-loader');
  $relatedPostContainer = $('.js-related-posts');
  $spinner = $('.c-related-loader__spinner');

  getUrlParams();

  if ($postsContainer.length !== 0 && firstLoad) {
    post_type = $postsContainer.data('pt');

    // Set initial sort
    if (search_value === '' && $sortInput.val() === 'relevance_desc') {
      sort_option = 'date_desc';
    } else {
      sort_option = $sortInput.val();
    }

    // Set inital search
    // search_value = $searchInput.val();
    initFunctions();
    getPosts();
    firstLoad = false;
  }

  $searchInput.on('keyup', (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      search();
    }
  });
});

function getPosts() {
  $loaders.addClass('is_loading');
  $.ajax({
    url: linku_ajax_url,
    type: 'post',
    data: {
      action: 'load_posts',
      post_type,
      filters,
      sort_option,
      search_value,
      paged: currentPage,
      popup_status,
    },
    dataType: 'json',
    success(response) {
      $searchInput.val(search_value);
      $postsContainer.html(response.postsHtml);
      $filterContainer.html(response.filtersHtml);
      $paginationContainer.html(response.paginationHtml);
      setNumPosts(response.numPosts);
      if (typeof checkFavourites === 'function') {
        checkFavourites();
      }
      $loaders.removeClass('is_loading');
      initFunctions();
      $('.real3dflipbook').each(function () {
        const fbdata = $(this).data('flipbook-options');
        $(this).flipBook({
          pdfUrl: fbdata.pdfUrl,
        });
      });
    },
    error(response) {
      console.log('error', response);
    },
  });
}

function initFunctions() {
  $filterItems = $('.js-filter-item');
  $toggleFilters = $('.js-toggle-filter');
  $resetFilters = $('.js-clear-filters');
  $pagination = $('.js-pagination');
  $toggleFilterPopup = $('.js-toggle-filter-popup');
  $filterPopup = $('.js-filter-popup');

  $filterItems.on('click', function () {
    const filter = $(this).data('filter');
    if ($(this)[0].type === 'radio') {
      filters = filters.filter((item) => !(item.includes($(this)[0].name)));
    }
    if (filters.includes(filter)) {
      filters = filters.filter((item) => item !== filter);
    } else {
      filters.push(filter);
    }

    popup_status = !!$filterPopup.hasClass('is-open');
    currentPage = 1;
    getPosts();
    updateUrl();
  });

  $resetFilters.on('click', (e) => {
    filters = [];
    getPosts();
    updateUrl();
  });

  $toggleFilters.on('click', function (e) {
    e.stopPropagation();
    if ($(this).hasClass('is-open')) {
      $(this).removeClass('is-open');
      $(this).next().slideUp();
    } else {
      $(this).addClass('is-open');
      $(this).next().slideDown();
    }
  });
  // $toggleFilters.each(function(){
  //     console.log("gevonden", $(this).find('.is_selected').length !== 0);
  //     if($(this).find('.is_selected').length !== 0){
  //         $(this).addClass('is_open');
  //         $(this).next().slideDown();
  //     }
  // });

  $pagination.on('click', function (e) {
    $('html, body').animate({
      scrollTop: $postsContainer.offset().top - 200,
    }, 'slow');
    if ($(this).data().page === 'prev') {
      currentPage -= 1;
    } else if ($(this).data().page === 'next') {
      currentPage += 1;
    } else {
      currentPage = Number($(this).data().page);
    }
    getPosts();
    updateUrl();
  });

  $toggleFilterPopup.on('click', (e) => {
    $filterPopup.each(function () {
      if ($(this).hasClass('is-open')) {
        $body.removeClass('menu-is-open');
        $(this).fadeOut().removeClass('is-open');
      } else {
        $(this).fadeIn().addClass('is-open');
        $body.addClass('menu-is-open');
      }
    });
  });
}

function sortPosts(selected) {
  sort_option = selected.value;
  getPosts();
}

function search() {
  search_value = $searchInput.val();
  if (search_value === '') {
    sort_option = 'date_desc';
  } else {
    sort_option = $sortInput.val();
  }
  currentPage = 1;
  updateUrl();
  getPosts();
}

function setNumPosts(num) {
  const singular = $numPosts.data('singular');
  const plural = $numPosts.data('plural');
  const found = $numPosts.data('found');
  const placeholder = $numPosts.data('placeholder');
  let newString = '';
  if (num === -1) {
    newString = `${placeholder}.`;
  } else if (num === 0) {
    newString = `Geen ${plural} ${found}.`;
  } else if (num === 1) {
    newString = `Eén ${singular} ${found}.`;
  } else {
    newString = `${num} ${plural} ${found}.`;
  }

  $numPosts.html(newString);
}

function getUrlParams() {
  let termFilters = []; let posttypeFilters = []; let
    metaFilters = [];
  if (urlParams.has('term')) {
    termFilters = urlParams.getAll('term');
    for (const i in termFilters) {
      termFilters[i] = `term.${termFilters[i]}`;
    }
  }
  if (urlParams.has('posttype')) {
    posttypeFilters = urlParams.getAll('posttype');
    for (const i in posttypeFilters) {
      posttypeFilters[i] = `posttype.${posttypeFilters[i]}`;
    }
  }
  if (urlParams.has('post_meta')) {
    metaFilters = urlParams.getAll('post_meta');
    for (const i in metaFilters) {
      metaFilters[i] = `post_meta.${metaFilters[i]}`;
    }
  }
  filters = termFilters.concat(posttypeFilters, metaFilters);
  if (urlParams.has('page')) {
    currentPage = urlParams.get('page');
  }
  if (urlParams.has('s')) {
    search_value = urlParams.get('s');
  } else {
    search_value = '';
  }
}

function updateUrl() {
  urlParams.delete('term');
  urlParams.delete('posttype');
  urlParams.delete('post_meta');
  urlParams.delete('s');
  for (const i in filters) {
    const type = filters[i].split('.')[0];
    const value = filters[i].split('.').pop();
    urlParams.append(type, value);
  }
  if (search_value) {
    urlParams.set('s', search_value);
  }
  urlParams.set('page', currentPage);
  window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
}

function getRelatedPosts(currentPosttype, collectionPosttype, currentPostId) {
  $spinner.addClass('is-loading');
  $relatedPostContainer.css('display', 'none');
  $.ajax({
    url: linku_ajax_url,
    type: 'post',
    data: {
      action: 'get_collection',
      post_type: currentPosttype,
      post_id: currentPostId,
      collection_type: collectionPosttype.value,
    },
    dataType: 'json',
    success(response) {
      $relatedPostContainer.html(response.collectionHtml);
      $spinner.removeClass('is-loading');
      $relatedPostContainer.css('display', 'flex');
    },
    error(response) {
      console.log('error', response);
    },
  });
}

function getItemsFromCollection(posttype, itemCollection) {
  $spinner.addClass('is-loading');
  $relatedPostContainer.css('display', 'none');
  console.log(itemCollection);
  $.ajax({
    url: linku_ajax_url,
    type: 'post',
    data: {
      action: 'get_items_from_collection',
      collection: itemCollection,
      posttype: posttype.value,
    },
    dataType: 'json',
    success(response) {
      $relatedPostContainer.html(response.collectionHtml);
      $spinner.removeClass('is-loading');
      $relatedPostContainer.css('display', 'flex');
    },
    error(response) {
      console.log('error', response);
    },
  });
}
