let $favoritesContainer,
    $favoriteButton,
    $removeFavoriteButton;

$(document).ready(function ($) {
    $favoritesContainer = $(".js-favorites-container");
    $favoriteButton = $(".js-favorite-button");
    if ($favoritesContainer.length !== 0 && firstLoad) {
        getFavorites();
        firstLoad = false;
    }
    if ($favoriteButton.length !== 0) {
        getFavoriteState();

        $favoriteButton.on('click', function () {
            let postId = $(this).data('postid');
            let state = $(this).data('state');
            let newState;
            let newText;
            if(state === 'true'){
                newState = "false"
                newText = "Verwijderd uit favorieten";
            }else{
                newState = "true"
                newText = "Toegevoegd aan favorieten";
            }
            setFavoriteState(postId, newState);
            setFavoriteButtonState($(this), newState);
            let messageContainer = $(this).next('.js-favorite-message');
            messageContainer.html(newText);
            messageContainer.show();
            window.setTimeout( function(){
                messageContainer.hide();
            }, 2000 );
        });
    }
});

function initFavoriteFunctions(){
    $removeFavoriteButton = $('.js-remove-favorite');
    $loaders = $('.js-loader');

    $removeFavoriteButton.on('click', function (e) {
        $(this).html('<i class="fas fa-circle-notch fa-spin"></i>');
        e.preventDefault();
        let postId = $(this).data('postid');
        getFavorites(postId);
    });
}

function getFavorites(removePostId = null){
    $loaders.addClass('is_loading');

    $.ajax({
        url: linku_ajax_url,
        type: 'post',
        data: {
            action: 'load_favorites',
            remove: removePostId
        },
        dataType: 'json',
        success: function (response) {
            $favoritesContainer.html(response.favoritesHtml);
            $loaders.removeClass('is_loading');
            initFavoriteFunctions();
        },
        error: function (response) {
            console.log('error', response);
        }
    });
}

function getFavoriteState(){
    $favoriteButton.each(function(){
        let postId = $(this).data('postid');
        let curElllement = $(this);
        $.ajax({
            url: linku_ajax_url,
            type: 'post',
            data: {
                action: 'get_favourite_state',
                postId: postId
            },
            dataType: 'json',
            success: function (response) {
                setFavoriteButtonState(curElllement, response.favoriteState);
            },
            error: function (response) {
                console.log('error', response);
            }
        });
    });
}

function setFavoriteState($postId, $state){
    $.ajax({
        url: linku_ajax_url,
        type: 'post',
        data: {
            action: 'set_favourite_state',
            postId: $postId,
            favState: $state
        },
        dataType: 'json',
        success: function (response) {
            console.log("response", response)
        },
        error: function (response) {
            console.log('error', response);
        }
    });
}

function setFavoriteButtonState($elem, $state, $showUpdateMessage = false){
    if($state === 'true'){
        $elem.data('state','true');
        $elem.html('<i class="fas fa-heart"></i>');
        $elem.removeClass('c-button--ghost')
    }else{
        $elem.data('state','false');
        $elem.html('<i class="fal fa-heart"></i>');
        $elem.addClass('c-button--ghost')
    }
}


