let $topicCharLink,
    $topicSpinner,
    $topicResultsList
;

$(document).ready(function ($) {
    $topicCharLink = $(".js-topic-char-link");
    $topicSpinner = $(".js-topic-spinner");
    $topicResultsList = $("#js-topic-list");

    $topicCharLink.click(function() {
        toggleTopicSpinner(true);
        $topicCharLink.removeClass('c-topic-nav__char-link--active');
        $(this).addClass('c-topic-nav__char-link--active');
        const char = $(this).data('char');
        ajax('get_topicslist', { char }, function(response) {
            toggleTopicSpinner(false);
            $topicResultsList.html(response.data.html);
        }, function(error) {
            toggleTopicSpinner(false);
            console.error(error);
        })
    });

    function toggleTopicSpinner(show) {
        $topicSpinner.toggleClass('c-loader__spinner--hide', !show);
    }
});


