
let $infoMenuToggles;

$(document).ready(function ($) {


    $infoMenuToggles = $(".js-information-menu__submenu-toggle");

    $infoMenuToggles.click(function() {
        $(this).parent().toggleClass('c-information-menu__item--submenu-active');
    });
    
    
    
});