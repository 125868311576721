/* instantiate observer object */
let observer = new IntersectionObserver(function(entries, observer) {
    /* loop through entries on intersection event */
    entries.forEach(entry => {

        /* if an entry is intersecting */
        if (entry.isIntersecting) {
            /* remove the observer */
            observer.disconnect();
            $("<link />", {
                "rel": "stylesheet",
                "href": "https://web.keesing.com/pub/config/onsmagazine/css/custom_client.css",
                "id": "keesing-style",
            }).appendTo("head");
            $("<script />", {
                "src": "https://web.keesing.com/pub/bundle-loader/bundle-loader.js",
                "id": "keesing-script",
                "data-wlpp-bundle": "portal",
            }).appendTo("head");
        }
    });
});
/* assign the observer object to the puzzle-portal element */

let $puzzlePortal = $("#puzzle-portal.homepage");

if ($puzzlePortal.length) {
    /* assign the observer object to the puzzle-portal element */
    observer.observe($puzzlePortal.get(0));
}

